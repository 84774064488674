<template>
  <b-row>
    <b-col md="5" sm="8" class="my-1">
      <b-form-group>
        <v-select v-model="dataBalai" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama" size="sm"
          :options="rsBalai" />
      </b-form-group>
    </b-col>
    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block   "></label>
        <b-form-select id="perPageSelect" v-model="perPage" s :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group>
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="2" class="my-1">
      <b-button variant="info" block size="sm" v-b-modal.modal-1>
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Add Ruas Jalan
      </b-button>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="4">
              <h6 class="text-primary"> </h6>
              <small class="text-primary">NamaRuas Jalan</small>
              <h5 class="text-primary"><strong>{{ data.item.nama_ruas_jalan }}</strong>
                <br><small class="text-primary">No Ruas <b> {{ data.item.no_ruas_jalan }} </b></small>
              </h5>


              <h6 class="mtm"> <small>Wilayah </small> <br><strong> {{ data.item.nama_balai }} BPJ Cilacap</strong> <br>
                <small><strong> {{ data.item.nm_wil }} Kabupaten Cilacap</strong> </small>
              </h6>
            </b-col>
            <b-col md="6">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <small class="text-primary">Jenis Pengerasan</small>
                  <b-row>
                    <b-col md="6">
                      <strong>Panjang & Lebar</strong> <br>
                      <h6><strong class="text-info" style="font-size :25px;">
                          <span v-if="data.item.panjang_ruas == null">{{ data.item.panjang }}</span>
                          <span v-if="data.item.panjang_ruas != null">{{ data.item.panjang }}</span>

                        </strong><small>KM</small>, <small>Lebar <strong>{{ data.item.lebar }}</strong> </small> Meter
                      </h6>
                    </b-col>
                    <b-col md="3">
                      <strong>Aspal</strong> <br>
                      <h6><strong class="text-info" style="font-size :25px;"> {{ data.item.panjang_aspal }}
                        </strong><small>KM</small>
                      </h6>
                    </b-col>
                    <b-col md="3">
                      <strong>Beton</strong> <br>
                      <h6><strong class="text-warning" style="font-size :25px;"> {{ data.item.panjang_beton }}
                        </strong><small>KM</small>
                      </h6>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>


            </b-col>
            <b-col md="2">

              <b-button block variant="outline-warning" size="sm" v-b-modal.modal-2
                @click="editData(data.item.id_ruas_jalan)">
                <feather-icon icon="Edit3Icon" size="15"></feather-icon> Edit
              </b-button>
              <b-button block variant="outline-danger" size="sm" @click="HapusData(data.item.id_ruas_jalan)">
                <feather-icon icon="TrashIcon" size="15"></feather-icon> Hapus
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="6">
      Total Ruas Jalan <strong> {{ items.length }}</strong>
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert, vSelect
  },
  props: {
    rsData: {},
    rsBalai: {}
  },
  data() {
    return {
      dataBalai: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsData;
      },
      set: function (newValue) {
        return this.rsData = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ["HapusData", "editData"],
  methods: {
    editData(value) {
      this.$emit("editData", value)
    },
    HapusData(value) {
      this.$emit("HapusData", value)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script> 
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.btn-block {
  display: block;
  width: 100%;
}

.mtm {
  margin-top: -5px;
}
</style>
