<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel_data :rsBalai=rsBalai :rsData=rsData @HapusData=HapusData @editData=editData></tabel_data>
        </b-card>
      </b-col>
    </b-row>
    <tambah-rusa-jalan-vue :rsBalai=rsBalai @SimapRuasJalan=SimapRuasJalan></tambah-rusa-jalan-vue>
    <edit-rusa-jalan :rsBalai=rsBalai :rsEdit=rsEdit @updateRuasJalan=updateRuasJalan></edit-rusa-jalan>
  </div>
</template>

<script>
import tabel_data from './component/tabel.vue'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCol, BRow,
  BTab, BCard, BButton
} from 'bootstrap-vue'
import tambahRusaJalanVue from './component/tambahRusaJalan.vue'
import EditRusaJalan from './component/editRusaJalan.vue'
export default {
  components: {
    BTabs, BCol, BRow,
    BButton,
    BTab, tabel_data, BCard, tambahRusaJalanVue, EditRusaJalan
  },
  data() {
    return {
      rsData: [],
      rsBalai: [],
      rsWilayah: [],
      rsEdit: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.loadData();
    this.balai_bidang()
  },
  methods: {
    async updateRuasJalan(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/master/update_jalan',
        data: {
          id_ruas_jalan: value.id_ruas_jalan,
          nama_ruas_jalan: value.nama_ruas_jalan,
          no_ruas_jalan: value.no_ruas_jalan,
          id_wilayah: value.id_wilayah.id_wilayah,
          panjang: value.panjang,
          lebar: value.lebar,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.loadData();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async editData(value) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/master/get_edit',
        data: {
          id_ruas_jalan: value
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsEdit = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async HapusData(value) {
      console.log(value)
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/master/hapus',
        data: {
          id_ruas_jalan: value
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.loadData();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async SimapRuasJalan(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/master/insert',
        data: {
          no_ruas_jalan: value.no_ruas_jalan,
          nama_ruas_jalan: value.nama_ruas_jalan,
          id_wilayah: value.id_wilayah.id_wilayah,
          panjang: value.panjang,
          lebar: value.lebar,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.loadData();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadData() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/master/load',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async balai_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/master/balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsBalai = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>