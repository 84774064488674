<template>
  <div>
    <b-modal id="modal-1" title="Tambah Ruas Jalan" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <validation-observer ref="simpleRules">
        {{ input }}
        <b-row>
          <b-col md="12">
            <b-form-group label="No Ruas" label-for="block">
              <validation-provider #default="{ errors }" rules="required" name="No Ruas">
                <cleave id="block" class="form-control" :raw="false" :options="options.block"
                  v-model="input.no_ruas_jalan" placeholder="NoRuas Jalan 177-P-K11"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="NamaRuas Jalan" label-for="basicInput">
              <validation-provider #default="{ errors }" rules="required" name="Nama Ruas">
                <b-form-input id="basicInput" placeholder="No Ruas" :state="errors.length > 0 ? false : null"
                  v-model="input.nama_ruas_jalan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Panjang Ruas" label-for="basicInput">
              <validation-provider #default="{ errors }" rules="required" name="Panjang Ruas">
                <b-form-input id="basicInput" placeholder="No Ruas" :state="errors.length > 0 ? false : null"
                  type="number" v-model="input.panjang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Lebar Ruas" label-for="basicInput">
              <validation-provider #default="{ errors }" rules="required" name="Panjang Ruas">
                <b-form-input id="basicInput" placeholder="No Ruas" :state="errors.length > 0 ? false : null"
                  v-model="input.lebar" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Wilayah BPJ">
              <validation-provider #default="{ errors }" rules="required" name="Wilayah Balai Bidang">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama" v-model="input.balai"
                  @input="loadWilayah()" :options="rsBalai" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-form-group label="Kapupaten">
              <validation-provider #default="{ errors }" rules="required" name="Kabupaten">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nm_wil" v-model="input.id_wilayah"
                  :options="wilayah" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </validation-observer>
      <hr>
      <b-row>
        <b-col md="12">
          <b-button variant="success" class="btn-icon" @click.prevent="validationForm">
            <feather-icon icon="SaveIcon" class="mr-50" size='15' /> Simpan
          </b-button>
          <b-button variant="warning" class="btn-icon mr-1 ml-1" @click="close()">
            <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Batal
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormInput, BFormGroup, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/config/Axios'
export default {
  components: {
    BButton,
    BModal,
    BAlert, BFormInput, BFormGroup, BRow, BCol, Cleave, vSelect, ValidationProvider, ValidationObserver
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    rsBalai: {

    }
  },
  data() {
    return {
      input: {},
      required,
      wilayah: [],
      options: {
        block: {
          delimiter: '-',
          blocks: [4, 1, 3],
          uppercase: true,
        },
      }

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["SimapRuasJalan"],
  methods: {
    SimapRuasJalan() {
      this.$emit("SimapRuasJalan", this.input)
    },
    async loadWilayah() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/master/wilayah',
        data: {
          id_bidang: self.input.balai.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.wilayah = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.SimapRuasJalan();
          this.close();
        }
      })
    },

    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>